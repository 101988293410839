import { createSlice } from '@reduxjs/toolkit';

import { TransactionType } from '@/dapp-sdk-v2';

export interface StakeStateProps {
  isMobileMenuOpen: boolean;
  blockExplorerURL: string;
  modalType: string | null;
  isWalletModalOpen: boolean;
  withdrawals: any[];
  stakeAmount: string;
  unstakeAmount: string;
  promptError: string;
  latestTxnHash: string;
  txnFeedback: boolean;
  txnLoader: boolean;
  isStaking: boolean;
  isUnstaking: boolean;
  tokenAllowence: string | null;
  tokenXAllowence: string | null;
  tokenAssociated: boolean;
  tokenXAssociated: boolean;
  approveTokenLoading: boolean;
  approveTokenXLoading: boolean;
  gasFeesData: any;
  limits: any;
  isRetryTransaction: boolean;
  nodeOperatorsBond: {
    data: {
      totalSDBonded: number;
      sdPriceInETH: number;
      totalSDBondedInETH: number;
      permissionlessValidatorCount: number;
      totalNodeOperatorBonding: number;
    };
    isLoading: boolean;
  };
  nodeOperatorsCount: {
    data: {
      permissionedNodeCount: number;
      permissionlessNodeCount: number;
      total: number;
    };
    isLoading: boolean;
  };
  ratedNetworkOverview: {
    data: {
      avgConsensusAprPercentage: number;
      avgExecutionAprPercentage: number;
      avgNetworkAprPercentage: number;
    };
    isLoading: boolean;
  };
  prices: any;
  ledgerTxSuccessFormDetails: any;
}

export const initialStakeState: StakeStateProps = {
  isMobileMenuOpen: false,
  blockExplorerURL: '',
  modalType: null,
  isWalletModalOpen: false,
  withdrawals: [],
  stakeAmount: '',
  unstakeAmount: '',
  promptError: '',
  latestTxnHash: '',
  txnLoader: false,
  txnFeedback: false,
  isStaking: false,
  isUnstaking: false,
  approveTokenLoading: false,
  approveTokenXLoading: false,
  tokenAssociated: true,
  tokenXAssociated: true,
  tokenAllowence: null,
  tokenXAllowence: null,
  gasFeesData: null,
  limits: null,
  isRetryTransaction: false,
  nodeOperatorsBond: {
    data: {} as any,
    isLoading: true,
  },
  nodeOperatorsCount: {
    data: {} as any,
    isLoading: true,
  },
  ratedNetworkOverview: {
    data: {} as any,
    isLoading: true,
  },
  prices: null,
  ledgerTxSuccessFormDetails: null,
};

const EXCEPTION_KEYS = [
  'nodeOperatorsBond',
  'nodeOperatorsCount',
  'ratedNetworkOverview',
  'prices',
  'limits',
  'blockExplorerURL',
];

export const stakeSlice = createSlice({
  name: 'stake',
  initialState: initialStakeState,
  reducers: {
    updateStakeData: (state: any, action: any) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[key] = value;
      }
    },
    clearStakeData: (state: any) => {
      for (const [key, value] of Object.entries(initialStakeState)) {
        if (!EXCEPTION_KEYS.includes(key)) state[key] = value;
      }
    },
  },
});

export const { updateStakeData, clearStakeData }: any = stakeSlice.actions;

export default stakeSlice.reducer;
