import { useEffect } from 'react';

import { useWithdraw } from '@stader-labs/web-sdk';
import { useDispatch, useSelector } from 'react-redux';

import { useChainData } from '@/hooks/useChainData';
import { updateStakeData } from '@/store';

// from "@stader-labs/web-sdk"

export const useWithdrawals = () => {
  const dispatch = useDispatch();
  const { fetchWithdrawals } = useWithdraw();

  const { walletAddress, config } = useSelector((state: any) => state.wallet);
  const { isStaking, isUnstaking, tokenAmount, tokenXAmount } = useSelector(
    (state: any) => state.user,
  );
  const { txn } = useSelector((state: any) => state.user);

  const { isValidChain: status } = useChainData();

  const fetchClaimData = async () => {
    let claimData = [];

    try {
      if (walletAddress) {
        claimData = await fetchWithdrawals(walletAddress);
      }
      dispatch(
        updateStakeData({
          withdrawals: claimData,
        }),
      );
    } catch (err: unknown) {
      if (err instanceof Error) {
        console.error(err.message);
      }
    }
  };

  useEffect(() => {
    fetchClaimData();
    setTimeout(() => {
      fetchClaimData();
    }, 4000);
  }, [
    walletAddress,
    txn.status,
    isStaking,
    isUnstaking,
    status,
    config,
    tokenAmount,
    tokenXAmount,
  ]);

  return null;
};
