import { useMemo } from 'react';

import { providers } from 'ethers';
import type { Account, Chain, Client, Transport } from 'viem';
import { useConnectorClient } from 'wagmi';

import { WagmiConfig } from '@/config/wagmi.config';

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client;

  if (!account || !chain || !transport) {
    return undefined;
  }

  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: chain.contracts?.ensRegistry?.address,
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Action to convert a Viem Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<WagmiConfig>({ chainId });
  return useMemo(() => (client ? clientToSigner(client) : undefined), [client]);
}
