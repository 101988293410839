import { erc20Abi } from 'viem';

import { NetworkConfigType } from '../types';
// ABI
import TokenXABI from './contracts/mainnet/TokenX.json';

const providerKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY || '';

export const MainNetConfig: NetworkConfigType = {
  chainId: '0x1',
  supportedChainIDs: [1],
  providerKey,
  chainName: 'Ethereum Mainnet',
  nativeCurrency: {
    name: 'Stader',
    symbol: 'ETH',
    xsymbol: 'ETHx',
    decimals: 18,
  },
  rpcUrls: {
    default: `https://eth-mainnet.g.alchemy.com/v2`,
  },
  blockExplorerUrls: ['https://etherscan.io'],
  contractAddresses: {
    erc20: '0x0000000000000000000000000000000000000000',
    xtoken: {
      token: '0xA35b1B31Ce002FBF2058D22F30f95D405200A15b',
    },
  },
  abis: {
    erc20: erc20Abi,
    xtoken: {
      token: TokenXABI,
    },
  },
};
