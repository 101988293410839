import * as React from 'react';

const GTM_ID = 'GTM-MVBT6T8';

const subRoute = process.env.NEXT_PUBLIC_SUB_ROUTE || '/';

export const GTMScriptHead = ({ gtm_id = GTM_ID }: { gtm_id?: string }) => {
  return (
    // eslint-disable-next-line @next/next/next-script-for-ga
    <script
      async
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtm_id}');`,
      }}
    />
  );
};

export const GTMScriptBody = ({ gtm_id = GTM_ID }: { gtm_id?: string }) => {
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtm_id}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
    </noscript>
  );
};

export const RudderStackScript = ({
  basePath = subRoute,
  fileName,
}: {
  basePath?: string;
  fileName: string;
}) => {
  const host = 'https://universe.staderlabs.com';
  const rsMinJs = `${basePath}analytics/${fileName}`;
  const dataPlane = host + '/l-p/rs-dataplane';
  const configUrl = host + '/rudderlabs/sourceConfig';

  return (
    <>
      <script
        type="text/javascript"
        async
        dangerouslySetInnerHTML={{
          __html: `!function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId","getUserId","getUserTraits","getGroupId","getGroupTraits"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="${rsMinJs}";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS(),
          e.load("2E0kslmrvgrH84NmPoKKsFKwhP5","${dataPlane}", {configUrl: "${configUrl}"}),
          e.page()}();`,
        }}
      />
    </>
  );
};

export const DefaultScripts = ({
  basePath = subRoute,
  fileName,
}: {
  basePath?: string;
  fileName: string;
}) => {
  return (
    <>
      <RudderStackScript basePath={basePath} fileName={fileName} />
    </>
  );
};

const UTM_PARAMS = {
  utm_referral: true,
  utm_source: true,
  utm_medium: true,
  utm_campaign: true,
  utm_term: true,
  utm_content: true,
};

export const getReferenceParams = () => {
  const { location } = window as any;
  // removed use of useRouter as there was delay in fetching url parameters
  // reading params from window.location instead.
  try {
    const searchStr = decodeURIComponent(location.search);
    const xQuery: any = new URLSearchParams(searchStr);
    const query: any = {};
    //This step is required else xQuery will always be empty object
    for (const [key, value] of xQuery.entries()) {
      query[key] = value;
    }
    const x: any = {};
    for (let key in UTM_PARAMS) {
      if (query[key]) {
        x[key] = query[key];
      }
    }
    return x;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('useRefrenceParams error', e);
    return {};
  }
};
