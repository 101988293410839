import React, { ReactNode } from 'react';

import {
  Box,
  BoxProps,
  Center,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Show,
} from '@chakra-ui/react';
import { Sheet } from 'react-modal-sheet';

interface ModalDrawerProps {
  isOpen: boolean;
  closeAlert: () => void;
  children: ReactNode;
  enableGesture?: boolean;
  header?: ReactNode;
  headerProps?: BoxProps;
  contentProps?: BoxProps;
  bodyProps?: BoxProps;
  closeOnOverlayClick?: boolean;
}

// Todo : Fix modal for mobile

const ModalDrawer: React.FC<ModalDrawerProps> = ({
  isOpen,
  closeAlert,
  children,
  header,
  closeOnOverlayClick,
  enableGesture = false,
  headerProps = {},
  contentProps = {},
  bodyProps = {},
}) => {
  return (
    <>
      <Show above="md">
        <Modal
          isOpen={isOpen}
          onClose={closeAlert}
          isCentered
          closeOnOverlayClick={closeOnOverlayClick}
        >
          <ModalOverlay bg="modalOverlay" />
          <ModalContent
            minW="560px"
            bg="backgroundSecondary"
            borderRadius="1.5rem"
            boxShadow="0px 32px 16px rgba(0, 0, 0, 0.12)"
            {...contentProps}
          >
            {header && <ModalHeader {...headerProps}>{header}</ModalHeader>}
            <ModalBody p="0" {...bodyProps}>
              {children}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Show>

      <Show below="md">
        {enableGesture ? (
          <Sheet
            isOpen={isOpen}
            onClose={closeAlert}
            initialSnap={0}
            snapPoints={[-50, 100, 0]}
            detent="content-height"
          >
            <Sheet.Container
              style={{
                borderTopRightRadius: '1.5rem',
                borderTopLeftRadius: '1.5rem',
              }}
            >
              <Sheet.Header />
              <Sheet.Content>
                {header && <Box {...headerProps}>{header}</Box>}
                <Center flexDirection="column" fontWeight="600">
                  {children}
                </Center>
              </Sheet.Content>
            </Sheet.Container>
          </Sheet>
        ) : (
          <Drawer isOpen={isOpen} onClose={closeAlert} placement="bottom">
            <DrawerOverlay bg="modalOverlay" />
            <DrawerContent
              bg="backgroundSecondary"
              borderTopRadius="2rem"
              maxH="75%"
              {...contentProps}
            >
              {header && <DrawerHeader {...headerProps}>{header}</DrawerHeader>}
              <DrawerBody p="0" {...bodyProps}>
                <Center flexDirection="column" fontWeight="600">
                  {children}
                </Center>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        )}
      </Show>
    </>
  );
};

export default ModalDrawer;
