export const firebaseConfig: any = {
  sdConfig: {
    apiKey: 'AIzaSyCxyWLiizVL49sl7dKLW9MCdWFeS_QOWas',
    authDomain: 'sd-staking.firebaseapp.com',
    projectId: 'sd-staking',
    storageBucket: 'sd-staking.appspot.com',
    messagingSenderId: '727967232846',
    appId: '1:727967232846:web:8a5c814e4884a02f0d641f',
    measurementId: 'G-K3QVG6533E',
  },
  ethConfig: {
    apiKey: 'AIzaSyDHcFviE4XbJwxt3BanDgEh2ugbcFmWTGA',
    authDomain: 'eth-dapp.firebaseapp.com',
    projectId: 'eth-dapp',
    storageBucket: 'eth-dapp.appspot.com',
    messagingSenderId: '42535140253',
    appId: '1:42535140253:web:b7cc738eba9b44ed548d74',
    measurementId: 'G-7L84C3D7TK',
  },
  bnbConfig: {
    apiKey: 'AIzaSyBNNhbpx1NYjISN4sPTJWy7D98E5rtEX74',
    authDomain: 'stader-binance.firebaseapp.com',
    projectId: 'stader-binance',
    storageBucket: 'stader-binance.appspot.com',
    messagingSenderId: '891020268340',
    appId: '1:891020268340:web:6a5cdb3551160349a90b61',
    measurementId: 'G-WT2G86DXVK',
  },
  polygonConfig: {
    apiKey: 'AIzaSyAbN23UYcIQEvSjymHtHAsmSp9jgiZpWg0',
    authDomain: 'stader-polygon.firebaseapp.com',
    projectId: 'stader-polygon',
    storageBucket: 'stader-polygon.appspot.com',
    messagingSenderId: '951177619439',
    appId: '1:951177619439:web:7512348197660ab3e3f81a',
    measurementId: 'G-VK5PJ7G7Q1',
  },
  hederaConfig: {
    apiKey: 'AIzaSyCpAi4CmKEmaonFu6uePMH9eThL5EF4c8w',
    authDomain: 'stader-hedera-analytics-v0.firebaseapp.com',
    projectId: 'stader-hedera-analytics-v0',
    storageBucket: 'stader-hedera-analytics-v0.appspot.com',
    messagingSenderId: '874435674431',
    appId: '1:874435674431:web:93c5708fd5e132b524138a',
    measurementId: 'G-93GW5D8632',
  },
  fantomConfig: {
    apiKey: 'AIzaSyAUOeKgn3WOjVMZP6ppzQ5uANiYmsU8pQE',
    authDomain: 'stader-fantom-v0.firebaseapp.com',
    projectId: 'stader-fantom-v0',
    storageBucket: 'stader-fantom-v0.appspot.com',
    messagingSenderId: '136156629655',
    appId: '1:559177656064:web:bd0dfcfad94263c0a3b5e5',
    measurementId: 'G-5XWXXWNPPD',
  },
};
