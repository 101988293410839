export interface IIndexable {
  [key: string]: any;
}

export type StrapiLiquidStakingOptions = {
  id: number;
  attributes: LiquidStakingOption;
};

export type Limits = {
  token: {
    totalLimit: number;
    exhaustedLimit: number;
  };
  tokenx: {
    totalLimit: number;
  };
};

export type WalletData = {
  key: string;
  name: string;
};

export type LiquidStakingOption = {
  key: string;
  link: string;
  title: string;
};

export type StrapiSubHeaderOption = {
  title: string;
  key: string;
  is_component: boolean;
  is_External: boolean;
  link: string;
  icon_key: string;
};

export type StrapiCommunityDetails = {
  section_details: StrapiSectionDetail;
  communities_data: StrapiCard[];
};

export type StrapiSectionDetail = {
  title?: string;
  sub_title?: string;
  cta_text?: string;
  cta_link?: string;
  section_id?: string;
};

export type StrapiCard = {
  key?: string;
  link?: string;
  title?: string;
  description?: string;
  cta_text?: string;
  img: any;
  img_key?: string;
};

export type StrapiRightSection = {
  title: string;
  subTitle?: string;
  key: string;
  data: {
    data: Array<any>;
  };
};

export type StrapiCenterHeaderData = {
  key: string;
  value?: string;
  title: string;
};

export type IIndexableTransaction = {
  [key in TransactionType]: {
    icon: any;
  };
};

export type objType = {
  [key: string]: string;
};

export enum TransactionType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export enum Token {
  SD = 'SD',
  ETH = 'ETH',
  BNB = 'BNB',
  POLYGON = 'POLYGON',
  HEDERA = 'HEDERA',
  FANTOM = 'FANTOM',
}

export const enum ReferralUserType {
  NEW = 'NEW',
  REFERRED = 'ALREADY_REFERRED',
  KOL = 'KOL',
}
