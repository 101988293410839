import { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useConnect } from 'wagmi';

import { updateWalletData } from '@/store';

const AUTOCONNECTED_CONNECTOR_IDS = ['safe', 'com.ledger'];

function useAutoConnect() {
  const dispatch = useDispatch();
  const { connect, connectors } = useConnect();

  useEffect(() => {
    AUTOCONNECTED_CONNECTOR_IDS.forEach((connector) => {
      const connectorInstance = connectors.find((c) => c.id === connector);

      if (connectorInstance) {
        connect({ connector: connectorInstance });
      }
    });
    dispatch(
      updateWalletData({
        isLoading: false,
      }),
    );
  }, [connect, connectors, dispatch]);
}

export default useAutoConnect;
