import omit from 'lodash/omit';
import { useRouter } from 'next/router';
import { useAccountEffect, useChains } from 'wagmi';

import { SWITCH_NETWORK_INVALID } from '@/constants/analytics';
import { QUERY_PARAMS } from '@/constants/query-params.constant';
import { trackEvent } from '@/dapp-sdk-v2';
import { useChainData } from '@/hooks/useChainData';

const useSetNetworkFromQP = () => {
  const router = useRouter();
  const chains = useChains();
  const { switchChain } = useChainData();

  useAccountEffect({
    onConnect: () => {
      const chainFromQP = router.query[QUERY_PARAMS.CHAIN] as
        | string
        | undefined;

      if (chainFromQP != null) {
        const chain = parseInt(chainFromQP);

        switchNetwork(chain);
      }
    },
  });

  const switchNetwork = (network: number) => {
    const isValidChain = chains.some((chain) => chain.id === network);
    if (isValidChain) {
      switchChain(network, {
        source: 'query-param',
      }).then(() => {
        router.push({
          query: omit(router.query, [QUERY_PARAMS.CHAIN]),
        });
      });
    } else {
      trackEvent(SWITCH_NETWORK_INVALID, {
        new_chain_id: network,
        reason: 'invalid chain id from query param',
        source_of_switch: 'query-param',
        available_chains: chains.map((chain) => chain.id),
      });
    }
  };
};

export { useSetNetworkFromQP };
