import Image from 'next/image';
import { ImageProps } from 'next/image';

import { getUrlForLocalImage, getUrlForStaticImage } from '../../utils/common';
import ExternalImage from './ExternalImage';

export const BaseImage = ({ src, ...rest }: ImageProps) => {
  const isExternal = (src as string)?.startsWith?.('http') || (src as string)?.startsWith?.('data:image');
  const isStaticImage = typeof src === 'object';

  const computedSrc = isExternal
    ? (src as string)
    : isStaticImage
      ? getUrlForStaticImage(src)
      : getUrlForLocalImage(src);

  if (isExternal) {
    return (
      <ExternalImage
        src={computedSrc}
        alt={rest.alt}
        h={`${rest.height}px`}
        w={`${rest.width}px`}
      />
    );
  }

  return <Image src={computedSrc} {...rest} />;
};

export default BaseImage;
