import React from 'react';

import { Center } from '@chakra-ui/react';

import { TransactionType } from '../../constants/common';
import { CloseIcon } from '../CloseIcon';
import { ModalDrawer } from '../ModalDrawer';
import ConfirmationBodyTemplate from './ConfirmationBodyTemplate';

interface UnstakeLimitExceedModalProps {
  isOpen: boolean;
  token: string;
  modalTitle: string;
  modalSubTitle?: string;
  closeAlert: () => void;
  primaryBtnTxt?: string; // view transactions
  secondaryBtnTxt?: string; // close or cancel
  onSubmitPrimary?: () => void;
  onSubmitSecondary?: () => void;
}

const UnstakeLimitExceedModal = ({
  isOpen,
  token,
  closeAlert,
  modalTitle,
  modalSubTitle,
  primaryBtnTxt,
  secondaryBtnTxt,
  onSubmitPrimary,
  onSubmitSecondary,
}: UnstakeLimitExceedModalProps) => {
  return (
    <ModalDrawer isOpen={isOpen} closeAlert={closeAlert}>
      <Center
        padding={{ base: '0 1rem 2.5rem', md: '2.5rem 3rem' }}
        fontWeight="600"
        flexDirection="column"
        textAlign="center"
        width="100%"
      >
        {
          <>
            <CloseIcon onClose={closeAlert} />
            <ConfirmationBodyTemplate
              modalTitle={modalTitle}
              modalSubTitle={modalSubTitle}
              primaryBtnTxt={primaryBtnTxt}
              secondaryBtnTxt={secondaryBtnTxt}
              onSubmitPrimary={onSubmitPrimary}
              onSubmitSecondary={onSubmitSecondary}
              token={token}
              transactionType={TransactionType.WARNING}
            />
          </>
        }
      </Center>
    </ModalDrawer>
  );
};

export default UnstakeLimitExceedModal;
