import { IIndexable } from './common';

// Override default messages
export const UNSTAKE_TITLE_MAPPINGS: IIndexable = {
  ETH: 'Unstake request successful',
};

// Override default messages
export const CLAIM_TITLE_MAPPINGS: IIndexable = {
  ETH: 'Successful withdraw',
};
