import {
  Chain,
  arbitrum,
  arbitrumSepolia,
  bsc,
  bscTestnet,
  holesky,
  mainnet,
  xLayer,
  xLayerTestnet,
} from 'viem/chains';

import { env } from '@/env';

interface ContractsConfig {
  nativeToken: {
    symbol: string;
    /**
     * undefined for native currency
     */
    address: `0x${string}` | undefined;
    decimals: number;
  };
  xToken: {
    symbol: string;
    address: `0x${string}`;
    decimals: number;
  };
  isWithdrawSupported: boolean;
}

const MAINNET_TOKENS: Record<Chain['id'], ContractsConfig> = {
  [mainnet.id]: {
    nativeToken: {
      symbol: mainnet.nativeCurrency.symbol,
      address: undefined,
      decimals: mainnet.nativeCurrency.decimals,
    },
    xToken: {
      symbol: 'ETHx',
      address: '0xA35b1B31Ce002FBF2058D22F30f95D405200A15b',
      decimals: 18,
    },
    isWithdrawSupported: true,
  },
  [arbitrum.id]: {
    nativeToken: {
      symbol: arbitrum.nativeCurrency.symbol,
      address: undefined,
      decimals: arbitrum.nativeCurrency.decimals,
    },
    xToken: {
      symbol: 'ETHx',
      address: '0xED65C5085a18Fa160Af0313E60dcc7905E944Dc7',
      decimals: 18,
    },
    isWithdrawSupported: false,
  },
  [xLayer.id]: {
    nativeToken: {
      symbol: 'WETH',
      address: '0x5a77f1443d16ee5761d310e38b62f77f726bc71c',
      decimals: 18,
    },
    xToken: {
      symbol: 'WETHx',
      address: '0xDBAaD20ffd67dfaeBdE40b842cB78eAa18F1BB74',
      decimals: 18,
    },
    isWithdrawSupported: false,
  },
  [bsc.id]: {
    nativeToken: {
      symbol: bsc.nativeCurrency.symbol,
      address: undefined,
      decimals: mainnet.nativeCurrency.decimals,
    },
    xToken: {
      symbol: 'BNBx',
      address: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
      decimals: 18,
    },
    isWithdrawSupported: true,
  },
} as const;

const TESTNET_TOKENS: Record<Chain['id'], ContractsConfig> = {
  [holesky.id]: {
    nativeToken: {
      symbol: holesky.nativeCurrency.symbol,
      address: undefined,
      decimals: holesky.nativeCurrency.decimals,
    },
    xToken: {
      symbol: 'ETHx',
      address: '0xFFF6552297ce1433F28b91C98338CECf06CAED08',
      decimals: 18,
    },
    isWithdrawSupported: true,
  },
  [arbitrumSepolia.id]: {
    nativeToken: {
      symbol: arbitrumSepolia.nativeCurrency.symbol,
      address: undefined,
      decimals: arbitrumSepolia.nativeCurrency.decimals,
    },
    xToken: {
      symbol: 'ETHx',
      address: '0x52312ea29135A468417F0C71d6A75CfEA75351b7',
      decimals: 18,
    },
    isWithdrawSupported: false,
  },
  [xLayerTestnet.id]: {
    nativeToken: {
      symbol: 'WETH',
      address: '0xBec7859BC3d0603BeC454F7194173E36BF2Aa5C8',
      decimals: 18,
    },
    xToken: {
      symbol: 'WETHx',
      address: '0x0B2fAadfe75fE6507Dc8F81f8331198C65cA2C24',
      decimals: 18,
    },
    isWithdrawSupported: false,
  },
  [bscTestnet.id]: {
    nativeToken: {
      symbol: 'BNB',
      address: undefined,
      decimals: 18,
    },
    xToken: {
      symbol: 'BNBx',
      address: '0x4e16D7Bb32a44e9D43053C86617fbc63223A226C',
      decimals: 18,
    },
    isWithdrawSupported: true,
  },
} as const;

const getConfigForEnv = <T = object>({
  mainnet,
  testnet,
}: {
  mainnet: Record<Chain['id'], T>;
  testnet: Record<Chain['id'], T>;
}): Record<Chain['id'], T> => {
  if (env.NEXT_PUBLIC_NETWORK === 'testnet') {
    return testnet;
  }

  return mainnet;
};

export const CONTRACTS_CONFIG = getConfigForEnv<ContractsConfig>({
  mainnet: MAINNET_TOKENS,
  testnet: TESTNET_TOKENS,
});
