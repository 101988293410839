import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { useEmbed } from '@/dapp-sdk-v2/hooks/useEmbed';

import {
  DEFAULT_REFERRAL_ID,
  LEDGER_LIVE_REFERRAL_ID,
} from '../constants/common';

const useQueryReferral = () => {
  const [stakeReferralId, setStakeReferralId] = useState(DEFAULT_REFERRAL_ID);
  const router = useRouter();
  const { isEmbed } = useEmbed();

  const {
    query: { utm_source },
  } = router;

  useEffect(() => {
    if (isEmbed) {
      setStakeReferralId(LEDGER_LIVE_REFERRAL_ID);
      return;
    } else if (utm_source) {
      setStakeReferralId(utm_source as string);
    }
  }, [isEmbed, utm_source]);
  return stakeReferralId;
};
export default useQueryReferral;
