import React from 'react';

import { Center } from '@chakra-ui/react';

import { TransactionType } from '../../types/common';
import { CloseIcon } from '../CloseIcon';
import { ModalDrawer } from '../ModalDrawer';
import SuccessErrorModalBodyTemplate from '../SuccessErrorModalBodyTemplate';

interface AddXSDMessageModalProps {
  error?: string;
  isOpen: boolean;
  onCancel: () => void;
  token: string;
  isTxnProcessing: boolean;
}

const AddXSDMessageModal = ({
  error,
  isOpen,
  token,
  isTxnProcessing = false,
  onCancel,
}: AddXSDMessageModalProps) => {
  const renderContent = () => {
    if (isTxnProcessing) {
      return (
        <SuccessErrorModalBodyTemplate
          isSpinnerRequired={true}
          modalTitle="Please check your wallet"
        />
      );
    } else {
      if (error) {
        return (
          <SuccessErrorModalBodyTemplate
            transactionType={TransactionType.ERROR}
            modalTitle={error || `Failed adding ${token} to your wallet`}
          />
        );
      } else {
        return (
          <SuccessErrorModalBodyTemplate
            transactionType={TransactionType.SUCCESS}
            modalTitle="Confirmed"
            modalSubTitle={`${token} successfully added to your wallet`}
          />
        );
      }
    }
  };
  return (
    <ModalDrawer isOpen={isOpen} closeAlert={onCancel}>
      <Center
        padding={{ base: '0 1rem 2.5rem', md: '2.5rem 3rem' }}
        fontWeight="600"
        flexDirection="column"
      >
        {
          <>
            <CloseIcon onClose={onCancel} />
            {renderContent()}
          </>
        }
      </Center>
    </ModalDrawer>
  );
};

export default AddXSDMessageModal;
