import { useMemo } from 'react';

import { useAccount } from 'wagmi';

const useLedgerLive = () => {
  const { connector } = useAccount();

  const isLedgerConnected = connector?.id === 'com.ledger';
  const isLedgerInjected = useMemo(() => {
    if (typeof window === 'undefined') return false;
    return window.ethereum?.isLedgerLive ?? false;
  }, []);

  return { isLedgerConnected, isLedgerInjected };
};

export { useLedgerLive };
