import { Chain } from 'wagmi/chains';

import { MainNetConfig } from './mainnet';
import { TestNetConfig } from './testnet';

const network = process.env.NEXT_PUBLIC_NETWORK;

export const etherChainConfig: Chain = {
  id: 1,
  name: 'ETH Smart Chain',
  // network: "eth",
  nativeCurrency: {
    symbol: 'ETH',
    decimals: 18,
    name: 'ETH',
  },
  rpcUrls: {
    default: {
      http: [
        'https://eth-mainnet.g.alchemy.com/v2/53TdGpuTA7-YZklBjbt4fLxQxpKoM296',
      ],
    },
    public: {
      http: [
        'https://eth-mainnet.g.alchemy.com/v2/53TdGpuTA7-YZklBjbt4fLxQxpKoM296',
      ],
    },
  },
  blockExplorers: {
    default: { name: 'Etherscan', url: 'https://etherscan.io' },
  },
  testnet: false,
};

export const etherTestnetChainConfig: Chain = {
  id: 17000,
  name: 'holesky',
  // network: "eth",
  nativeCurrency: {
    symbol: 'ETH',
    decimals: 18,
    name: 'ETH',
  },
  rpcUrls: {
    default: {
      http: ['https://ethereum-holesky.publicnode.com'],
    },
    public: {
      http: ['https://ethereum-holesky.publicnode.com'],
    },
  },
  blockExplorers: {
    default: { name: 'Etherscan', url: 'https://holesky.etherscan.io' },
  },
  testnet: true,
};

export const getETHconfig = () =>
  network === 'mainnet' ? MainNetConfig : TestNetConfig;

export const getETHChains = () =>
  network === 'mainnet' ? [etherChainConfig] : [etherTestnetChainConfig];
