import { z } from 'zod';

import { SUPPORTED_CHAINS } from '@/constants/constants';

const envSchema = z.object({
  NEXT_PUBLIC_WC_PROJECT_ID: z.string({
    required_error: 'NEXT_PUBLIC_WC_PROJECT_ID is required',
  }),
  NEXT_PUBLIC_BLOGS_KEY: z.string({
    required_error: 'NEXT_PUBLIC_BLOGS_KEY is required',
  }),
  NEXT_PUBLIC_ALCHEMY_KEY: z.string({
    required_error: 'NEXT_PUBLIC_ALCHEMY_KEY is required',
  }),
  NEXT_PUBLIC_USERPILOT_KEY: z.string().optional(),
  NEXT_PUBLIC_BICONOMY_KEY: z.string().optional(),
  NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  NEXT_PUBLIC_BEARER_TOKEN: z.string().optional(),
  NEXT_PUBLIC_SUB_ROUTE: z.string({
    required_error: 'NEXT_PUBLIC_SUB_ROUTE is required',
  }),
  NEXT_PUBLIC_CHAINVINE_API_KEY: z.string().optional(),
  NEXT_PUBLIC_NETWORK: z.enum(['mainnet', 'testnet']).catch('mainnet'),
  NEXT_PUBLIC_KELPDAO_URL: z
    .string()
    .url({
      message: 'NEXT_PUBLIC_KELPDAO_URL must be a valid URL',
    })
    .default('https://kelpdao.xyz'),
  NEXT_PUBLIC_TOKEN: z.enum(SUPPORTED_CHAINS).catch('ETH'),
  NEXT_PUBLIC_STRAPI_URL: z
    .string({
      required_error: 'NEXT_PUBLIC_STRAPI_URL is required',
    })
    .url({
      message: 'NEXT_PUBLIC_STRAPI_URL must be a valid URL',
    })
    .default('https://cms-stage.staderlabs.click'),
});

export type Env = z.infer<typeof envSchema>;

const getSafeEnv = (env: unknown): Env => {
  const result = envSchema.safeParse(env);

  if (!result.success) {
    throw new Error(result.error.errors.map((e) => e.message).join('\n'));
  }

  return result.data;
};

const env = getSafeEnv({
  NEXT_PUBLIC_WC_PROJECT_ID: process.env.NEXT_PUBLIC_WC_PROJECT_ID,
  NEXT_PUBLIC_BLOGS_KEY: process.env.NEXT_PUBLIC_BLOGS_KEY,
  NEXT_PUBLIC_ALCHEMY_KEY: process.env.NEXT_PUBLIC_ALCHEMY_KEY,
  NEXT_PUBLIC_USERPILOT_KEY: process.env.NEXT_PUBLIC_USERPILOT_KEY,
  NEXT_PUBLIC_BICONOMY_KEY: process.env.NEXT_PUBLIC_BICONOMY_KEY,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_BEARER_TOKEN: process.env.NEXT_PUBLIC_BEARER_TOKEN,
  NEXT_PUBLIC_SUB_ROUTE: process.env.NEXT_PUBLIC_SUB_ROUTE ?? '',
  NEXT_PUBLIC_CHAINVINE_API_KEY: process.env.NEXT_PUBLIC_CHAINVINE_API_KEY,
  NEXT_PUBLIC_NETWORK: process.env.NEXT_PUBLIC_NETWORK,
  NEXT_PUBLIC_KELPDAO_URL: process.env.NEXT_PUBLIC_KELPDAO_URL,
  NEXT_PUBLIC_TOKEN: process.env.NEXT_PUBLIC_TOKEN,
  NEXT_PUBLIC_STRAPI_URL: process.env.NEXT_PUBLIC_STRAPI_URL,
});

export { env, getSafeEnv };
