import { default as Arrow } from './arrow.svg';
import { default as bnb } from './blockchains/binance.svg';
import { default as eth } from './blockchains/eth.svg';
import { default as CheckMark } from './checkmark.svg';
import { default as Coins } from './coins.svg';
import { default as CryptoCurrencyLoading } from './crypto-currency-loading.svg';
import { default as DocumentLegalLowHammer } from './document-legal-low-hammer.svg';
import { default as DocumentsFileText } from './documents-file-text.svg';
import { default as EthSidebar } from './ethereum-sidebar.svg';
import { default as FAQ } from './faq.svg';
import { default as FunctionNotice } from './function-notice.svg';
import { default as HammerLegal } from './hammer-legal.svg';
import { default as HandCoinsCurrency } from './hand-coins-currency.svg';
import { default as InsuranceHandsHeart } from './insurance-hands-heart.svg';
import { default as JewelRingsWedding } from './jewel-rings-wedding.svg';
import { default as Layout } from './layout.svg';
import { default as Lock } from './lock.svg';
import { default as MeatSteak } from './meat-steak.svg';
import { default as MoneyCoinsExchange } from './money-coins-exchange.svg';
import { Stargate, Transporter, XSwap } from './partners';
import { default as PolygonShape } from './polygon.svg';
import { default as SDStake } from './sd-stake.svg';
import { default as StaderInput } from './stader-input.svg';
import { default as Stake } from './stake.svg';
import { default as ThumpsUpCircle } from './thumps-up-circle.svg';
import { default as TxSign } from './tx-sign.svg';
import { default as User } from './user.svg';
import { default as Wheel } from './wheel.svg';

export * from './blockchains';
export * from './blockchains_themed';
export * from './audits';
export * from './socials';
export * from './transaction';
export * from './wallets';
export * from './partners';

export { default as Close } from './close.svg'; // ModalClose
export { default as Close_mobile } from './close_mobile.svg'; // MobileClose(Rectangle)

export const iconMapping = (iconKey: string) => {
  switch (iconKey) {
    case 'faq':
      return FAQ;
    case 'stake':
      return Stake;
    case 'lock':
      return Lock;
    case 'document-legal-low-hammer':
      return DocumentLegalLowHammer;
    case 'documents-file-text':
      return DocumentsFileText;
    case 'jewel-rings-wedding':
      return JewelRingsWedding;
    case 'function-notice':
      return FunctionNotice;
    case 'crypto-currency-loading':
      return CryptoCurrencyLoading;
    case 'insurance-hands-heart':
      return InsuranceHandsHeart;
    case 'thumps-up-circle':
      return ThumpsUpCircle;
    case 'stader-input':
      return StaderInput;
    case 'hand-coins-currency':
      return HandCoinsCurrency;
    case 'money-coins-exchange':
      return MoneyCoinsExchange;
    case 'hammer-legal':
      return HammerLegal;
    case 'layout':
      return Layout;
    case 'wheel':
      return Wheel;
    case 'sd-stake':
      return SDStake;
    case 'tx-sign':
      return TxSign;
    case 'coins':
      return Coins;
    case 'meat-steak':
      return MeatSteak;
    case 'polygon':
      return PolygonShape;
    case 'eth':
      return eth;
    case 'bnb':
      return bnb;
    case 'eth-sidebar':
      return EthSidebar;
    case 'user':
      return User;

    case 'arrow':
      return Arrow;

    case 'xswap':
      return XSwap;

    case 'transporter':
      return Transporter;

    case 'stargate':
      return Stargate;

    default:
      return null;
  }
};

export {
  DocumentLegalLowHammer,
  InsuranceHandsHeart,
  ThumpsUpCircle,
  HandCoinsCurrency,
  MoneyCoinsExchange,
  HammerLegal,
  Layout,
  Wheel,
  StaderInput,
  SDStake,
  Coins,
  MeatSteak,
  PolygonShape,
  CheckMark,
};

export { default as MessageQuestionCheckmark } from './message-question-checkmark.svg';
export { default as DocumentsFileCopy } from './documents-file-copy.svg';
export { default as Sun } from './sun.svg';
export { default as MoonNightMode } from './moon-night-mode.svg';
export { default as Arrow } from './arrow.svg';
export { default as ChervonRight } from './chervon-right.svg';
export { default as LinkShear } from './link-shear.svg';
export { default as RightArrow } from './right-arrow.svg';
export { default as VerifiedUser } from './verified-user.svg';
export { default as SpaceId } from './spaceId.svg';
export { default as Unstoppable } from './unstoppable.svg';
export { default as Book } from './book.svg';
