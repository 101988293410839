import { NetworkConfigType } from '../types';
// ABI
import ERC20TOKENABI from './contracts/mainnet/ERC20.json';
import KOLReferral from './contracts/mainnet/KOLReferral.json';
import TokenXABI from './contracts/mainnet/TokenXBal.json';
import TokenStakeManagerABI from './contracts/mainnet/TokenXInteractionPool.json';

const providerKey = process.env.NEXT_PUBLIC_ALCHEMY_KEY || '';

export const MainNetConfig: NetworkConfigType = {
  chainId: '0x38',
  supportedChainIDs: [56],
  providerKey,
  chainName: 'Smart Chain',
  nativeCurrency: {
    name: 'Binance',
    symbol: 'BNB',
    xsymbol: 'BNBx',
    decimals: 18,
  },
  rpcUrls: {
    default: `https://bsc-mainnet.nodereal.io/v1`,
  },
  blockExplorerUrls: ['https://bscscan.com'],
  contractAddresses: {
    erc20: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    xtoken: {
      token: '0x1bdd3Cf7F79cfB8EdbB955f20ad99211551BA275',
      stakingContract: '0x3b961e83400D51e6E1AF5c450d3C7d7b80588d28',
    },
    referral: '0xa76B46b181B09cC25D02eA412e499E3afbA4c372',
  },
  abis: {
    erc20: ERC20TOKENABI,
    xtoken: {
      token: TokenXABI,
      stakingContract: TokenStakeManagerABI,
    },
    referral: KOLReferral,
  },
};
