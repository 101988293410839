import { useEffect } from 'react';

import axios from 'axios';
import { useRouter } from 'next/router';

import CMSJson from '@/constants/cms.json';
import { IIndexable, useDappSDKProvider } from '@/dapp-sdk-v2';

const CHAINVINE_KEY = process.env.NEXT_PUBLIC_CHAINVINE_API_KEY;
const CHAINVINE_URL = 'https://api.chainvine.xyz/api/v2';

const headers: IIndexable = {
  'X-API-KEY': CHAINVINE_KEY,
};

const useChainvine = () => {
  const { walletAddress } = useDappSDKProvider();
  const router = useRouter();

  const cmsData: any = { ...CMSJson };

  const {
    query: { utm_source, slug, referrer_id },
  } = router;

  const registerChainvine = async () => {
    const userData = await axios.post(
      `${CHAINVINE_URL}/users`,
      {
        wallet_address: walletAddress,
      },
      {
        headers: headers,
      },
    );
    const userId = userData?.data?.id || '';
    if (userId) {
      axios.post(
        `${CHAINVINE_URL}/user/${userId}/campaign/${slug}/link/referral`,
        {
          wallet_address: walletAddress,
          referrer_id: referrer_id,
          campaign_id: slug,
        },
        {
          headers: headers,
        },
      );
    }
  };

  useEffect(() => {
    if (
      utm_source &&
      walletAddress &&
      slug &&
      cmsData?.is_chainvine_referral_alllowed
    ) {
      registerChainvine();
    }
  }, [utm_source, walletAddress, slug]);
};

export default useChainvine;
