import Script from 'next/script';

import CMSJson from '@/constants/cms.json';

const cmsData: any = { ...CMSJson };

const ChatBot = () => {
  if (cmsData && cmsData.enable_chat) {
    return (
      <>
        <Script
          id="chatbot"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            var _smartsupp = _smartsupp || {};
            _smartsupp.key = 'fc7a0f089927a2b699de7d1445aa5d3b7ddf5498';
            _smartsupp.color = '#07A658';
            window.smartsupp||(function(d) {
                var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
                s=d.getElementsByTagName('script')[0];c=d.createElement('script');
                c.type='text/javascript';c.charset='utf-8';c.async=true;
                c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
            })(document);
            `,
          }}
        />
        <Script
          id="chatbot-1"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            smartsupp('group', 'NZXSn5Pvkp'); //group Stader Core Support
            `,
          }}
        />
      </>
    );
  }
  return null;
};

export default ChatBot;
