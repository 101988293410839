import { useCallback, useEffect, useState } from 'react';

import { Box, Button, Spinner, useMediaQuery } from '@chakra-ui/react';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';

import { WagmiConfig } from '@/config/wagmi.config';
import { useChainData } from '@/hooks/useChainData';
import { updateWalletData } from '@/store';

import { SCANNERLINK } from '../../constants/constants';
import { EVENTS } from '../../constants/events';
import {
  BLOCKCHAIN_EXPLORER,
  CONNECT_WALLET,
  COPY_ADDRESS,
  DISCONNECT_WALLET,
  WALLET_MODAL,
} from '../../constants/firebase';
import useDomainResolution from '../../hooks/useDomainResolution';
import { emitEvent } from '../../utils/common';
import { trackEvent } from '../../utils/firebase';
import AccountModal from '../AccountModal';
import WalletInfo from '../WalletInfo';
import WalletMessageModal from '../WalletMessageModal';
import { getCurrentChain } from '@/utils/common';
import { usePathname } from 'next/navigation';

const HeaderWallletConnect = () => {
  const pathname = usePathname()

  const token = getCurrentChain(pathname);
  const [isMobileOrTablet] = useMediaQuery('(max-width: 768px)');
  const dispatch = useDispatch();
  const { firebaseEventsData } = useSelector((state: any) => state.event);
  const {
    isLoading,
    isConnected,
    walletAddress,
    walletModal,
    isWrongChain,
    activeConnector,
  } = useSelector((state: any) => state.wallet);
  const { isWalletModalOpen } = useSelector((state: any) => state.stake);

  const { chain } = useAccount<WagmiConfig>();

  const { openConnectModal } = useConnectModal();

  const { isEmbed, isSafeApp } = useSelector((state: any) => state.user);

  const domain = useDomainResolution();

  const { switchChain } = useChainData();

  const [isOpenAccountModal, setIsOpenAccountModal] = useState(false);

  const tractFirebaseEvent = useCallback(
    (eventName: string, eventParams = {}) => {
      trackEvent(eventName, { ...firebaseEventsData, ...eventParams });
    },
    [firebaseEventsData],
  );

  const handleConnectWallet = useCallback(() => {
    openConnectModal?.();
    tractFirebaseEvent(CONNECT_WALLET, { wallet_cta: 'top right' });
  }, [dispatch, isMobileOrTablet, tractFirebaseEvent, openConnectModal]);

  const onCloseAccountModal = useCallback((isWalletDisconnect: boolean) => {
    setIsOpenAccountModal(false);
    if (isWalletDisconnect) {
      tractFirebaseEvent(DISCONNECT_WALLET);
      emitEvent(EVENTS.HANDLE_DISCONNECT);
    }
  }, []);

  useEffect(() => {
    const elements = [
      document.querySelector('html'),
      document.querySelector(' body'),
    ];
    if (!isWalletModalOpen) {
      elements.forEach((element) => element?.classList.add('enableScroll'));
    } else {
      elements.forEach((element) => element?.classList.remove('enableScroll'));
    }
  }, [isWalletModalOpen]);

  const closeWalletMessageModal = () => {
    dispatch(
      updateWalletData({
        walletModal: {
          isVisible: false,
          error: '',
        },
      }),
    );
  };

  return (
    <>
      <Box ml="1rem">
        {isLoading && (
          <Button
            variant="secondary"
            // onClick={() => {}}
            borderRadius="0.5rem"
            disabled
          >
            <Spinner size="md" color="primary" />
          </Button>
        )}
        {!isLoading &&
          (isConnected ? (
            <WalletInfo
              activeConnector={activeConnector!}
              walletAddress={walletAddress}
              onCopyAddress={() => tractFirebaseEvent(COPY_ADDRESS)}
              onClick={(isAccountModalVisible) => {
                setIsOpenAccountModal(isAccountModalVisible);
                tractFirebaseEvent(WALLET_MODAL);
              }}
              domain={domain}
            />
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={handleConnectWallet}
                borderRadius="0.5rem"
              >
                Connect wallet
              </Button>
            </>
          ))}
      </Box>

      {walletModal.isVisible && (
        <WalletMessageModal
          isOpen={walletModal.isVisible}
          closeModal={closeWalletMessageModal}
          error={walletModal.error}
          isWrongChain={isWrongChain}
          onSubmitPrimary={switchChain}
          isEmbed={isEmbed || isSafeApp}
        />
      )}

      {isOpenAccountModal && (
        <AccountModal
          isOpen={isOpenAccountModal}
          onCancel={onCloseAccountModal}
          onCopyAddress={() =>
            tractFirebaseEvent(COPY_ADDRESS, { type: 'wallet_modal' })
          }
          scannerLink={`${chain?.blockExplorers?.default.url}${SCANNERLINK[token]}${walletAddress}`}
          onClickScanner={() => tractFirebaseEvent(BLOCKCHAIN_EXPLORER)}
          walletAddress={walletAddress}
          domain={domain}
          activeConnector={activeConnector}
        />
      )}
    </>
  );
};

export default HeaderWallletConnect;
