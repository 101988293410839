import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { WALLET_KEYS } from '../constants/common';

export const useEmbed = () => {
  const { connectorID } = useSelector((state: any) => state.wallet);
  const router = useRouter();

  const isLedger = Boolean(router.query.embed) || false;

  const isSafeApp = connectorID === WALLET_KEYS.SAFE;
  const isEmbed = connectorID === WALLET_KEYS.LEDGER_LIVE || isLedger;

  return { isEmbed, isSafeApp };
};
