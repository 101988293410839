import { useCallback, useMemo } from 'react';

import { useAccount, useChains, useSwitchChain } from 'wagmi';

import { CONTRACTS_CONFIG } from '@/config/contracts.config';
import { WagmiConfig } from '@/config/wagmi.config';
import { SWITCH_NETWORK } from '@/constants/analytics';
import { trackEvent } from '@/dapp-sdk-v2';

const useChainData = () => {
  const chains = useChains<WagmiConfig>();
  const { chainId = chains[0].id } = useAccount();

  const { switchChainAsync } = useSwitchChain();

  const isValidChain = useMemo(() => {
    return chains.some((chain) => chain.id === chainId);
  }, [chainId, chains]);

  const contractConfig = useMemo(() => {
    if (!chainId) {
      return null;
    }

    if (chains.find((chain) => chain.id === chainId)) {
      return CONTRACTS_CONFIG[chainId];
    }

    return null;
  }, [chainId, chains]);

  const handleSwitch = useCallback(
    async (
      newChainId: number = chains[0].id,
      { source = 'network-switcher' } = {},
    ) => {
      trackEvent(SWITCH_NETWORK, {
        previous_chain_id: chainId,
        new_chain_id: newChainId,
        chain_name: chains.find((chain) => chain.id === newChainId)?.name,
        source_of_switch: source,
      });

      return await switchChainAsync({ chainId: newChainId }).catch((err) => {
        console.error('Error switching chain:', err);
      });
    },
    [chainId, chains, switchChainAsync],
  );

  const isWithdrawSupported = contractConfig?.isWithdrawSupported ?? false;

  const withdrawSupportedChains = useMemo(
    () =>
      Object.entries(CONTRACTS_CONFIG)
        .filter(([_, config]) => config.isWithdrawSupported)
        .map(([chainId]) => parseInt(chainId)),
    [],
  );

  const withdrawSupportedChainData = useMemo(
    () =>
      withdrawSupportedChains &&
      chains.filter((chain) => withdrawSupportedChains.includes(chain.id)),
    [chains, withdrawSupportedChains],
  );

  return {
    chainId,
    switchChain: handleSwitch,
    isValidChain,
    contractConfig,
    isWithdrawSupported,
    withdrawSupportedChains,
    withdrawSupportedChainData,
  };
};

export { useChainData };
