import React, { useMemo } from 'react';

import { Box, Flex } from '@chakra-ui/react';
import { Connector } from 'wagmi';

import { AccountModalActionItem, TextPrimary } from '@/themes';

import { walletIconMapping } from '../../../../constants/walletMenuOptions';
import { useEmbed } from '../../../../hooks/useEmbed';
import { shortenAddress } from '../../../../utils/common';
import CopyIcon from '../../../CopyIcon';
import BaseImage from '@/dapp-sdk-v2/components/Image';

interface WalletAddressProps {
  walletAddress?: string;
  activeConnector?: Connector;
  onCopyAddress(): void;
  onCancel(isDisconnected?: boolean): void;
}
const WalletAddress = ({
  walletAddress,
  activeConnector,
  onCopyAddress,
  onCancel,
}: WalletAddressProps) => {
  const handleDisconnect = () => {
    onCancel(true);
  };

  const walletIcon = useMemo(() => {
    if (activeConnector && activeConnector.icon) {
      return activeConnector.icon;
    }
    if(activeConnector?.id && walletIconMapping[activeConnector.id]){
      return walletIconMapping[activeConnector.id];
    }
    return walletIconMapping['ledgerlive'];
  }, [activeConnector]);


  const { isEmbed, isSafeApp } = useEmbed();

  const shortenWalletAddress = useMemo(() => {
    return shortenAddress(walletAddress || '');
  }, [walletAddress]);

  return (
    <Flex
      justifyContent="space-between"
      p={{
        base: '1rem',
        md: '1.5rem 2rem',
      }}
      bg="backgroundPrimary"
      borderRadius="0.5rem"
      alignItems="center"
      width="100%"
    >
      <Flex alignItems="center">
        {walletIcon && (
          <Box pos="relative" h="40px" w="40px">
            <BaseImage src={walletIcon} alt="wallet" fill />
          </Box>
        )}
        <TextPrimary ml="1rem" fontWeight="600" fontSize="16px">
          {shortenWalletAddress}
        </TextPrimary>
        <Box as="button" h="24px" w="24px" cursor="pointer" ml="1rem">
          <CopyIcon address={walletAddress} onClick={onCopyAddress} />
        </Box>
      </Flex>
      {!(isEmbed || isSafeApp) && (
        <AccountModalActionItem onClick={handleDisconnect}>
          Disconnect
        </AccountModalActionItem>
      )}
    </Flex>
  );
};

export default WalletAddress;
