import { ValueOf } from 'type-fest';

export const EVENTS = {
  HANDLE_CONNECT: 'handleConnect',
  HANDLE_DISCONNECT: 'handleDisconnect',
  HANDLE_TRANSACTION: 'handleTransaction',
  OPEN_WALLET_MODAL: 'openWalletModal',
  HANDLE_SWITCH_NETWORK: 'handleSwitchNetwork',
  HANDLE_RETRY_TRANSACTION: 'handleRetryTransaction',
};

export const TRANSACTION_TYPE = {
  STAKE: 'stake',
  UNSTAKE: 'unstake',
  WITHDRAW: 'withdraw',
  APPROVE_TOKEN: 'approve_token',
  APPROVE_TOKEN_X: 'approve_token_x',
  ASSOCIATE_TOKEN: 'associate_token',
  ASSOCIATE_TOKEN_X: 'associate_token_x',
  SWAP: 'swap',
} as const;

export type AppTransactionType = ValueOf<typeof TRANSACTION_TYPE>;
