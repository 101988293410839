import {
  BinanceWallet,
  BitKeep,
  Bitget,
  BladeIcon,
  CBW,
  Coin98,
  CoinHub,
  ExodusIcon,
  FrontierIcon,
  HaloIcon,
  HashPackIcon,
  ImtokenIcon,
  LedgerDarkIcon,
  MM,
  MathWallet,
  Nabox,
  OKX,
  PhantomIcon,
  RabbyIcon,
  SafeIcon,
  SafePal,
  TrustWallet,
  WC,
} from '@/icons';

import { IIndexable } from './common';

export const walletIconMapping: IIndexable = {
  injected: MM,
  metamask: MM,
  coinbasewallet: CBW,
  walletconnect: WC,
  okex: OKX,
  trustwallet: TrustWallet,
  safepal: SafePal,
  mathwallet: MathWallet,
  coin98: Coin98,
  bitkeep: BitKeep,
  coinhub: CoinHub,
  nabox: Nabox,
  binance: BinanceWallet,
  BinanceW3WSDK: BinanceWallet,
  ledgerlive: LedgerDarkIcon,
  ledger: LedgerDarkIcon,
  frontier: FrontierIcon,
  halo: HaloIcon,
  phantom: PhantomIcon,
  exodus: ExodusIcon,
  hashpack: HashPackIcon,
  blade: BladeIcon,
  rabbywallet: RabbyIcon,
  safe: SafeIcon,
  bitget: Bitget,
  imtoken: ImtokenIcon,
};

export const walletLabelMapping: IIndexable = {
  injected: 'Metamask',
  metamask: 'Metamask',
  coinbasewallet: 'Coinbase Wallet',
  walletconnect: 'Wallet Connect',
  okex: 'OKX Wallet',
  trustwallet: 'Trust Wallet',
  safepal: 'SafePal Wallet',
  mathwallet: 'Math Wallet',
  coin98: 'Coin98 Wallet',
  bitkeep: 'Bitkeep Wallet',
  bitget: 'Bitget Wallet',
  coinhub: 'Coinhub Wallet',
  nabox: 'Nabox Wallet',
  binance: 'Binance Wallet',
  ledgerLive: 'Ledger Live',
  ledger: 'Ledger',
  frontier: 'Frontier Wallet',
  halo: 'Halo Wallet',
  phantom: 'Phantom Wallet',
  exodus: 'Exodus Wallet',
  hashpack: 'HashPack Wallet',
  blade: 'Blade Wallet',
  rabbywallet: 'Rabby Wallet',
  safe: 'Safe Wallet',
  imtoken: 'Imtoken Wallet',
};
