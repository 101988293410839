import { DisclaimerComponent } from '@rainbow-me/rainbowkit';

const TermsAndCondition: DisclaimerComponent = ({ Text, Link }) => {
  const TERMS_URL = 'https://staderlabs.com/terms-of-service';
  return (
    <Text>
      By connecting a wallet, you agree to Stader’s{' '}
      <Link href={TERMS_URL}>Terms of Service.</Link>
    </Text>
  );
};

export default TermsAndCondition;
