import { erc20Abi } from 'viem';

import { NetworkConfigType } from '../types';
// ABI
import TokenXABI from './contracts/testnet/TokenX.json';

export const TestNetConfig: NetworkConfigType = {
  chainId: '0x4268',
  supportedChainIDs: [17000],
  // providerKey,
  providerKey: '', //holesky on't require key
  chainName: 'Ethereum Testnet',
  nativeCurrency: {
    name: 'Stader',
    symbol: 'ETH',
    xsymbol: 'ETHx',
    decimals: 18,
  },
  rpcUrls: {
    default: `https://ethereum-holesky.publicnode.com`,
  },
  blockExplorerUrls: ['https://holesky.etherscan.io'],
  contractAddresses: {
    // erc20 Mainnnet 0x0000000000000000000000000000000000000000
    erc20: '0xdD69DB25F6D620A7baD3023c5d32761D353D3De9',
    xtoken: {
      token: '0xFFF6552297ce1433F28b91C98338CECf06CAED08',
    },
  },
  abis: {
    erc20: erc20Abi,
    xtoken: {
      token: TokenXABI,
    },
  },
};
