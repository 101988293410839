import React, { useMemo, useState } from 'react';

import { Box, Button, Flex, Show, Text, Textarea } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { RadioButtons } from '@/components/ui/RadioButton';
import { trackEvent } from '@/dapp-sdk-v2';
import { TransactionType } from '@/dapp-sdk-v2/constants/common';
import { TX_FEEDBACK_CTA } from '@/dapp-sdk-v2/constants/firebase';
import { RootState } from '@/store';

interface TransactionFeedbackModalProps {
  type: TransactionType;
  onClose: () => void;
}

const REASON_OPTIONS = {
  [TransactionType.SUCCESS]: [
    'Great UX',
    'High APR',
    'Ledger communications',
    'Something else',
  ],
  [TransactionType.ERROR]: [
    "Oops, my transaction didn't go through",
    'I changed my mind about staking',
    'Trouble with my Ledger device',
    'Something else came up',
  ],
  [TransactionType.WARNING]: [
    "Oops, my transaction didn't go through",
    'I changed my mind about staking',
    'Trouble with my Ledger device',
    'Something else came up',
  ],
};

const TransactionFeedbackModal: React.FC<TransactionFeedbackModalProps> = ({
  type,
  onClose,
}) => {
  const [selectedReason, setSelectedReason] = useState<string>('');
  const [detail, setDetail] = useState<string>('');
  const lastTransaction = useSelector(
    (state: RootState) => state.user.lastTransaction,
  );
  const txData = useSelector((state: RootState) => state.user.txn);

  const title = useMemo(() => {
    if (type === TransactionType.SUCCESS) {
      return 'What brought you to Stader';
    }

    if (type === TransactionType.ERROR) {
      return 'Tell us what went wrong';
    }

    // ? type === TransactionType.WARNING
    return 'We’re here to help! How can we assist you today?';
  }, [type]);

  const handleSubmit = () => {
    if (!selectedReason) {
      return void 0;
    }

    trackEvent(TX_FEEDBACK_CTA, {
      type,
      reason: selectedReason,
      description: detail,

      // ? may contain empty string, hence `||` instead of `??`
      transaction: txData?.type || lastTransaction,
      tx_hash: txData?.hash || 'not available',
      tx_status: txData?.status || 'not available',
    });

    onClose();
  };

  const renderTextArea = () => {
    return (
      <Textarea
        placeholder="Mention in detail..."
        borderColor="seperator"
        resize="none"
        color="textSecondary"
        p={2}
        fontSize={14}
        borderRadius={8}
        value={detail}
        onChange={(e) => setDetail(e.target.value)}
      />
    );
  };

  return (
    <Flex
      gap={{
        base: 8,
        md: 1.5,
      }}
      direction="column"
      as="form"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Text fontWeight={600}>{title}</Text>

      <Box>
        {type && (
          <RadioButtons
            name="reason"
            options={REASON_OPTIONS[type]}
            value={selectedReason}
            onChange={setSelectedReason}
          >
            <Show below="md">{renderTextArea()}</Show>
          </RadioButtons>
        )}
      </Box>

      <Show above="md">{renderTextArea()}</Show>

      <Flex
        mt={2}
        justifyContent={{
          base: 'center',
          md: 'flex-start',
        }}
      >
        <Button
          color="default"
          variant="oulined"
          borderColor="textPrimary"
          border="1px solid"
          disabled={!selectedReason}
          cursor={!selectedReason ? 'not-allowed' : 'pointer'}
          type={'submit'}
        >
          Submit
        </Button>
      </Flex>
    </Flex>
  );
};

export { TransactionFeedbackModal };
