import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import eventReducer, {
  EventStateProps,
  initialEventState,
  updateEventData,
} from './slices/eventSlice';
import stakeReducer, {
  StakeStateProps,
  clearStakeData,
  initialStakeState,
  updateStakeData,
} from './slices/stakeSlice';
import userReducer, {
  UserStateProps,
  clearUserData,
  initialUserState,
  resetTxnData,
  updateTransactionStatus,
  updateTxnData,
  updateTxnHash,
  updateUserData,
} from './slices/userSlice';
import walletReducer, {
  WalletStateProps,
  clearWalletData,
  initialWalletState,
  updateWalletData,
} from './slices/walletSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    wallet: walletReducer,
    event: eventReducer,
    stake: stakeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export {
  updateUserData,
  clearUserData,
  resetTxnData,
  updateTxnData,
  updateTransactionStatus,
  updateTxnHash,
  updateWalletData,
  clearWalletData,
  updateStakeData,
  clearStakeData,
  updateEventData,
  initialEventState,
  initialStakeState,
  initialUserState,
  initialWalletState,
};
export type {
  EventStateProps,
  StakeStateProps,
  UserStateProps,
  WalletStateProps,
};
