import { BNB, FANTOM, HEDERA, SD } from './constants';

const WEB_SDK_ENV = process.env.NEXT_PUBLIC_ENV || 'dev';

export const TERMS_URL = 'https://staderlabs.com/terms-of-service';

export const VID = 'vid';

export enum TransactionType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export interface IIndexable {
  [key: string]: any;
}

export const NON_APPROVAL_CHAINS_STAKE = [FANTOM];
export const NON_APPROVAL_CHAINS_UNSTAKE = [FANTOM];
export const NO_MIN_MAX_LIMIT_CHAINS = [BNB];
export const UNSTAKE_CONFIRMATION_CHAINS = [SD]; // TODO ad sd cases
export const STAKE_CONFIRMATION_CHAINS = [SD];
export const REQUIRE_APPROVAL_AMOUNT_CHAINS = [HEDERA];

// keys should be according to strapi
export const WALLET_KEYS = {
  COINBASEWALLET: 'coinbasewallet',
  METAMASK: 'metamask',
  WALLETCONNECT: 'walletconnect',
  OKX: 'okx',
  TRUSTWALLET: 'trustwallet',
  SAFEPAL: 'safepal',
  MATHWALLET: 'mathwallet',
  COIN98: 'coin98',
  BITKEEP: 'bitkeep',
  COINHUB: 'coinhub',
  NABOX: 'nabox',
  BINANCE: 'binance',
  LEDGER_LIVE: 'com.ledger',
  LEDGER: 'ledger',
  FRONTIER: 'frontier',
  HALO: 'halo',
  PHANTOM: 'phantom',
  EXODUS: 'exodus',
  RABBY: 'rabbywallet',
  SAFE: 'safe',
  BITGET: 'bitget',
  IMTOKEN: 'imtoken',
};

const YIELD_API_PATH: any = {
  dev: 'http://localhost:4317',
  stage: 'https://stage-yeilds.staderlabs.click',
  preprod: 'https://beta-yeilds.staderlabs.com',
  prod: 'http://yeilds.staderlabs.com',
};

export const YIELD_API_URL = YIELD_API_PATH[WEB_SDK_ENV];

export const DEFAULT_REFERRAL_ID = 'stader_labs';
export const LEDGER_LIVE_REFERRAL_ID = 'ledger_live';
