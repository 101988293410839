// Types
import { IIndexable } from './common';

export const TXN_STATUSES: IIndexable = {
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

type Keys = keyof typeof TXN_STATUSES;
export type TXN_STATUS = (typeof TXN_STATUSES)[Keys];

export const TXN_TYPES: IIndexable = {
  TOKEN_APPROVE: 'Token_approve',
  ERC20_APPROVE: 'ERC20_approve',
  XTOKEN_APPROVE: 'XToken_approve',
  STAKE: 'Stake',
  UNSTAKE: 'Unstake',
  CLAIM: 'Claim',
  XTOKEN_ASSOCIATE: 'XToken_associate',
};
