import React, { useMemo } from 'react';

import { Box, Flex } from '@chakra-ui/react';

const RadioButton: React.FC<{
  isChecked: boolean;
  onClick: () => void;
  children: string;
}> = (props) => {
  const styleProps = useMemo(() => {
    if (props.isChecked) {
      return {
        fontWeight: 500,
        backgroundColor: {
          base: 'seperator',
          md: 'transparent',
        },
        color: 'textPrimary',
        borderColor: {
          base: 'transparent',
          md: 'textPrimary',
        },
      };
    }
    return {
      fontWeight: 400,
      backgroundColor: undefined,
      color: 'textSecondary',
      borderColor: 'seperator',
    };
  }, [props.isChecked]);

  return (
    <Box
      as="label"
      onClick={props.onClick}
      w={{
        base: '100%',
        md: 'auto',
      }}
    >
      <Box
        {...styleProps}
        cursor="pointer"
        borderWidth="1px"
        borderRadius={8}
        _focus={{
          boxShadow: 'outline',
        }}
        px={2}
        py={1}
        fontSize={14}
        textAlign={{
          base: 'center',
          md: 'left',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

interface RadioButtonsProps {
  name: string;
  options: string[];
  value: string;
  onChange: (value: string) => void;
  children?: React.ReactNode;
}

const RadioButtons: React.FC<RadioButtonsProps> = (props) => {
  return (
    <Flex wrap={'wrap'} gap={2}>
      {props.options.map((option) => {
        return (
          <React.Fragment key={option}>
            <RadioButton
              key={option}
              onClick={() => props.onChange(option)}
              isChecked={option === props.value}
            >
              {option}
            </RadioButton>

            {option === props.value && props.children}
          </React.Fragment>
        );
      })}
    </Flex>
  );
};

export { RadioButtons };
