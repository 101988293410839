import { BNB, ETH } from '../constants/constants';
import { getBNBChains, getBNBconfig } from './bnb';
import { getETHChains, getETHconfig } from './ether';

export const getConfig = (
  token: string,
  environment?: string,
  chainId?: number
): any => {
  switch (token) {
    case ETH:
      return getETHconfig();
    case BNB:
      return getBNBconfig();
  }
  return getETHconfig();
};

export const getChains = (
  token: string,
  environment?: string,
  chainId?: number,
) => {
  switch (token) {
    case ETH:
      return getETHChains();
    case BNB:
      return getBNBChains();
  }
  return getETHChains();
};
